// PaymentHistory.jsx
import React, { useEffect, useState,useContext } from 'react';
// import paymentData from '../staticData/PaymentHistory.json';
import '../styles/PaymentHistory.css'; // Ensure the CSS file is correctly imported
// import StandardPlan from './StandardPlan';
import BillingContact from './BillingContact';
import PaymentCard from '../staticData/PaymentCard.json'
import GlobalContext from "../context/GlobalContext";


import { Api } from "../api";
import Cookies from "js-cookie";


const PaymentHistory = () => {
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [billingInfo, setBillingInfo] = useState({});
  const {themeToggle} = useContext(GlobalContext);
  const token = Cookies.get("token");

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  useEffect(() => {
    if (token) {
      Api.profile(token).then(profileRes => {
        if (profileRes.status === 200) {
          Api.cardPayment(token).then(cardRes => {
            if (cardRes.status === 200) {
              const combinedData = {
                name: profileRes?.data.data.first_name + ' ' + profileRes?.data?.data?.last_name, // Adjust according to your API response
                email: profileRes?.data?.data?.email,
                paymentCard: cardRes?.data?.type, // Adjust according to your API response
                cardNumber: cardRes?.data?.data?.card_no, // Adjust according to your API response
                // ... other data
              };
              // console.log("combine data" , combinedData)
              setBillingInfo(combinedData);
            }
          }).catch(error => {
            console.error("Error fetching card data:", error);
          });
        }
      }).catch(error => {
        console.error("Error fetching profile data:", error);
      });
     
      Api.paymentHistory(token).then(res => {
        if (res.status === 200 && res?.data && res?.data?.data?.length > 0) {
          setPaymentHistory(res.data.data);
        }
      }).catch(error => {
        // Handle error here (e.g., show a message)
        console.error("Error fetching payment history:", error);
      });
    }
  }, [token]);
  return (
    <div className='dark:bg-[#232123]'>
      <div className="container mx-auto p-4 ">
        <div className="flex flex-col md:flex-row md:space-x-4">
          <div className="md:w-1/2">
            <BillingContact data={billingInfo} />
          </div>
          {/* <div className="md:w-1/2">
          <StandardPlan data={PaymentCard.standardPlan} />
        </div> */}
        </div>
        <div className="shadow-md sm:rounded-lg md:rounded dark:bg-black">
          <div className="p-4 bg-gray-50 dark:bg-black">
            <h2 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">Payment History</h2>
          </div>
          <div className={`overflow-x-auto relative items-center justify-center text-center ${themeToggle ? 'bg-black': ''}`}>
            <table className="w-full text-lg text-gray-400 border-b mt-3">
              <thead className={` mb-3 ${themeToggle ? 'bg-black': ''}`}>
                <tr>
                  <th scope="col" className="w-1/4 py-3 px-6 sticky top-0 dark:bg-black  dark:text-white">Status</th>
                  <th scope="col" className="w-1/4 py-3 px-6 sticky top-0 dark:bg-black dark:text-white">Date</th>
                  {/* <th scope="col" className="w-1/4 py-3 px-6 sticky top-0 dark:bg-black dark:text-white">Period</th> */}
                  <th scope="col" className="w-1/4 py-3 px-6 sticky top-0 dark:bg-black dark:text-white">Amount</th>
                </tr>
              </thead>
            </table>
            <div className="tbody-scroll max-h-64 overflow-y-scroll">
              <table className="w-full text-sm text-gray-500">
                <tbody className="divide-y divide-gray-200">
                  {paymentHistory.map((payment) => (

                    <tr key={payment.id} className={`${themeToggle?'bg-black':'bg-white'}`}>
                      <td className="w-1/4 py-4 px-6 whitespace-nowrap">
                        <span className="inline-block text-green-600 border-1 border-green-500 rounded-full px-3 py-1 ">{payment.status}</span>
                      </td>
                      <td className={`w-1/4 py-4 px-6 ${themeToggle?"text-white" :"text-black"}`}>{formatDate(payment.created_at)}</td>
                      {/* <td className="w-1/4 py-4 px-6 dark:text-white">{payment.period}</td> */}
                      <td className={`w-1/4 py-4 px-6 ${themeToggle?"text-white" :"text-black"}`}>{payment.amount}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentHistory;
