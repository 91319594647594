import React from 'react';
import { LexaLogo } from "../../staticData/icons";
import { Link } from "react-router-dom";
import messageIcon from "../../assets/msgIcon.svg"
import locationIcon from "../../assets/locationIcon.svg"
import countryFlag from "../../assets/countryFlag.svg"
import easypaisa from '../../assets/landingPage-imgs/footer-easypaisa.svg'
import jazzCash from '../../assets/landingPage-imgs/FooterJazzcash.svg'
import footerBankTransfer from '../../assets/landingPage-imgs/footerBankTransfer.svg'
import footer_twitter from '../../assets/landingPage-imgs/footer_twitter.png'
import facebook from '../../assets/landingPage-imgs/Footerfacebook.png'
import insta from '../../assets/landingPage-imgs/footrerInstagram.png'
import linkedin from '../../assets/landingPage-imgs/footetLinkedIn.png'
import bgImage from '../../assets/landingPage-imgs/bg.jpg'
const BlogsFooter = ({ latestBlogs, relatedPosts }) => {
    let blogsToDisplay;
    let sectionTitle;
    let displayContactInfo = false;

    if (latestBlogs && latestBlogs.length > 0) {
        blogsToDisplay = latestBlogs;
        sectionTitle = "Latest Blogs";
    } else if (relatedPosts && relatedPosts.length > 0) {
        blogsToDisplay = relatedPosts;
        sectionTitle = "Related Posts";
    } else {
        displayContactInfo = true; // Set to true if no blogs or related posts are available
    }
    return (
        <footer className="bg-cover bg-no-repeat mt-20">
            {/* // style={{ backgroundImage: `url(${bgImage})` }}> */}
        <div className='mx-auto max-w-screen-xl space-y-8 px-4 pt-16 sm:px-6 lg:space-y-16 lg:px-8'>
          <div className='grid grid-cols-1 gap-8 lg:grid-cols-3'>
            <div>
              <div className='text-teal-600'>
                {LexaLogo}
                <svg
                  className='h-8'
                  viewBox='0 0 118 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  {/* Path data omitted for brevity */}
                </svg>
              </div>
              <p className='mt-2 max-w-xs text-white'>
                LEXA is a not a product, its a vision that revolutionizes the
                legal landscape by simplifying legal research through AI.
              </p>
              <ul className='mt-8 flex gap-6 pl-0'>
                <li>
                  <a
                    href='https://www.facebook.com/profile.php?id=61554795011681'
                    rel='noreferrer'
                    target='_blank'
                    className='text-white transition hover:opacity-75'
                  >
                    <img src={facebook} alt='' />
                    <span className='sr-only'>Facebook</span>
                  </a>
                </li>

                <li>
                  <a
                    href='https://www.instagram.com/lexa_lawyer/'
                    rel='noreferrer'
                    target='_blank'
                    className='text-white transition hover:opacity-75'
                  >
                    <span className='sr-only'>Instagram</span>
                    <img src={insta} alt='' />
                  </a>
                </li>

                <li>
                  <a
                    href='https://www.linkedin.com/company/lexa_lawyer/'
                    rel='noreferrer'
                    target='_blank'
                    className='text-white transition hover:opacity-75'
                  >
                    <span className='sr-only'>LinkedIn</span>
                    <img src={linkedin} alt='' />
                  </a>
                </li>
                <li>
                  <a
                    href='https://www.linkedin.com/company/lexa_lawyer/'
                    rel='noreferrer'
                    target='_blank'
                    className='text-white transition hover:opacity-75'
                  >
                    <span className='sr-only'>twitter</span>
                    <img src={footer_twitter} alt='' />
                  </a>
                </li>
              </ul>
            </div>
            <div className='grid grid-cols-1 gap-8 sm:grid-cols-2 lg:col-span-2 lg:grid-cols-3'>
              {/* <div>
                            <p className="font-medium text-white">Quick Links</p>
                            <ul className="mt-6 space-y-4 text-sm pl-0">
                                <li><Link className="text-white transition hover:opacity-75" to='/'>Home</Link>
                                </li>
                                <li><Link className="text-white transition hover:opacity-75" to='/'>About</Link>
                                </li>
                                <li><Link className="text-white transition hover:opacity-75" to='/'>Services</Link>
                                </li>
                                <li><Link className="text-white transition hover:opacity-75" to='/'>Features</Link>
                                </li>
                                <li><Link className="text-white transition hover:opacity-75" to='/'>Contact</Link>
                                </li>
                            </ul>
                        </div> */}
              <div>
                <p className='font-medium text-white'>Quick Links</p>
                <ul className='mt-6 space-y-4 text-sm pl-0'>
                  <li>
                    <Link
                      className='text-white transition hover:opacity-75'
                      to='/Refund-Policy'
                    >
                      Refund Policy
                    </Link>
                  </li>
                  <li>
                    <Link
                      className='text-white transition hover:opacity-75'
                      to='/terms-and-conditions'
                    >
                      Terms and conditions
                    </Link>
                  </li>
                  <li>
                    <Link
                      className='text-white transition hover:opacity-75'
                      to='/Privacy-Policy'
                    >
                      Privacy Policy
                    </Link>
                  </li>
                </ul>
              </div>
              {displayContactInfo ? (
                <div>
                  <p className='font-medium text-white'>Contact Us</p>
                  <ul className='mt-6 space-y-4 text-sm pl-0'>
                    <li className='flex items-center text-white transition hover:opacity-75'>
                      <img src={locationIcon} alt='' className='w-5 h-5 mr-2' />
                      Karachi, Pakistan
                    </li>
                    <li className='flex items-center'>
                      <img src={countryFlag} alt='' className='w-5 h-5 mr-2' />
                      <a
                        href='tel:+923352232931'
                        className='text-white transition hover:opacity-75'
                      >
                        +92-335-2232931
                      </a>
                    </li>
                    <li className='flex items-center'>
                      <img src={messageIcon} alt='' className='w-5 h-5 mr-2' />
                      <a
                        href='mailto:contactus@lexa.lawyer'
                        className='text-white transition hover:opacity-75'
                      >
                        contactus@lexa.lawyer
                      </a>
                    </li>
                  </ul>
                </div>
              ) : (
                <div>
                  <p className='font-medium text-white'>{sectionTitle}</p>
                  <ul className='mt-6 space-y-4 text-sm pl-0'>
                    {blogsToDisplay.map((blog) => (
                      <li key={blog.id}>
                        <Link
                          className='text-white transition hover:opacity-75'
                          to={`/blogs-view/${blog.slug || blog.end_url}`}
                        >
                          {blog.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <div>
                <div className='imgs mt-6 text-sm pl-0'>
                  <img src={jazzCash} alt='jazzcash' />
                  <img src={easypaisa} alt='easypaisa' />
                  <img src={footerBankTransfer} alt='footerBankTransfer' />
                </div>
              </div>
            </div>
          </div>
          <p className='text-xs text-gray-500 text-center'>
            &copy; 2024. Kuzzee Solution. All rights reserved.
          </p>
        </div>
      </footer>
    )
};

export default BlogsFooter;
