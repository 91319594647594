import Cookies from 'js-cookie';

const Auth = (state, action) => {
  switch (action.type) {
    case 'SET_USER_INFO':
      return {
        ...state,
        userInfo: action.payload,
      }
    case 'SET_CHAT_DATA':
      return {
        ...state,
        chatData: [...state.chatData, ...action.payload],
      }
    case 'SET_CURRENT_REPLY':
      const tempChatData = [...state.chatData]
      if (action.payload === '<EOC>' || action.payload === '<EOF>') {
        // action.payload hamry new message k equal hajo socket se aa raha ha character by character
        tempChatData.push({
          role: 'ai',
          content: state.currentRecievingChat,
        })

        return {
          ...state,
          chatLoading: false,
          chatData: tempChatData,
          sendBtnLoading: false,
          currentRecievingChat: '',
        }
      } else if (action.payload === '<SOF>') {
        tempChatData.pop()
        return {
          ...state,
          chatLoading: false,
          chatData: tempChatData,
          sendBtnLoading: true,
          currentRecievingChat: '',
        }
      } else {
        // this condition is working on the basis that new message jo charqachter by charater aa raha ha os ki length 15 se kam ha to concatinate karonstate.currentRecievingChat else jo response aa raha woi dekha do
        if (action.payload.length < 20) {
          return {
            ...state,
            currentRecievingChat: state.currentRecievingChat + action.payload,
          }
        } else {
          return {
            ...state,
            currentRecievingChat: action.payload,
          }
        }
      }

    case 'SET_EOC_RECEIVED':
      return {
        ...state,
        isEOCReceived: action.payload,
      }
    case 'SET_JUDGEMENT_CHAT_DATA':
      return {
        ...state,
        judgementChatData: [...state.judgementChatData, ...action.payload],
      }

    case 'SET_QUERY_TYPE':
      return {
        ...state,
        queryType: action.payload,
      }
    case 'SET_SELECTED_TAB':
      return {
        ...state,
        selectedTab: action.payload,
      }
    case 'SET_PAYMENT_PLAN':
      return {
        ...state,
        paymentPlan: action.payload,
      }
    case 'SET_ANNUAL_CLICKED':
      return {
        ...state,
        isAnnualClicked: action.payload,
      }
    case 'SET_BLUR_BG':
      return {
        ...state,
        blurBG: action.payload,
      }
    case 'SET_THEME_TOGGLE':
      return {
        ...state,
        themeToggle: action.payload,
      }

    case 'SET_CHAT_LOADING':
      return {
        ...state,
        chatLoading: action.payload,
      }

    case 'SET_CHAT_LOADING_ID':
      return {
        ...state,
        chatLoadingId: action.payload,
      }

    case 'SET_JUDGEMENT_CHAT_LOADING':
      return {
        ...state,
        judgementchatLoading: action.payload,
      }

    case 'SET_ERROR_MESSAGE':
      return {
        ...state,
        errorMessage: action.payload,
      }
    case 'SET_JUDGEMENT_ERROR_MESSAGE':
      return {
        ...state,
        judgementErrorMessage: action.payload,
      }

    case 'SET_INPUT_QUERY':
      return {
        ...state,
        inputQuery: action.payload,
      }
    case 'SET_JUDGEMENT_INPUT_QUERY':
      return {
        ...state,
        judgementInputQuery: action.payload,
      }
    case 'SET_SUGGESTION_QUERY':
      return {
        ...state,
        suggestionQuery: action.payload,
      }
    case 'SET_SEND_BTN_LOADING':
      return {
        ...state,
        sendBtnLoading: action.payload,
      }
    case 'SET_JUDGEMENT_SEND_BTN_LOADING':
      return {
        ...state,
        judgementSendBtnLoading: action.payload,
      }
    case 'CLEAR_CHAT_DATA':
      return {
        ...state,
        chatData: [],
        // judgementChatData: [],
      }
    case 'CLEAR_JUDGEMENT_CHAT_DATA':
      return {
        ...state,
        judgementChatData: [],
      }
    case 'SET_CHAT_VIEW_VISIBILITY':
      return {
        ...state,
        isChatViewVisible: action.payload,
      }

    case 'SET_SELECT_RESPONSIVE_TABE':
      return {
        ...state,
        isSelectResponsiveTab: action.payload,
      }
    case 'SET_SEARCH_QUERY':
      return {
        ...state,
        sQuery: action.payload,
      }
    case 'SET_SELECTED_CATEGORY':
      return {
        ...state,
        selectedCategory: action.payload,
      }
    case 'SET_CORRECT_ANSWERS_COUNT':
      return {
        ...state,
        quizCorrectAnswersCount: action.payload,
      }
    case 'SET_ATTEMPTED_QUESTIONS':
      return {
        ...state,
        quizAttemptedQuestions: action.payload,
      }
    case 'SET_ACTIVE_MCQS':
      return {
        ...state,
        activeMcqs: action.payload,
      }
    case 'SET_SELECT_CHAT_ID':
      return {
        ...state,
        selectChatID: action.payload,
      }
    case 'SET_CHAT_HISTORY':
      return {
        ...state,
        chatHistory: action.payload,
      }
    case 'SET_AWAITING_RESPONSE':
      return {
        ...state,
        isAwaitingResponse: action.payload,
      }
    case 'SET_ACTIVE_MCQS':
      return {
        ...state,
        activeMcqs: action.payload,
      }
    case 'SET_SELECT_CHAT_ID':
      return {
        ...state,
        selectChatID: action.payload,
      }
    case 'SET_CHAT_HISTORY':
      return {
        ...state,
        chatHistory: action.payload,
      }
    case 'TOGGLE_DARK_MODE':
      return {
        ...state,
        themeToggle: !state.themeToggle,
      }
    case 'SET_SYSTEM_THEME': {
      const isDarkMode = action.payload === 'dark'
      document.documentElement.classList.toggle('dark', isDarkMode)
      return {
        ...state,
        themeToggle: isDarkMode,
      }
    }
    case 'SET_FINAL_JUDGMENT_DATA':
      return {
        ...state,
        finalJudgmentData: [...state.finalJudgmentData, action.payload],
      }
    case 'SET_SELECTED_DOCUMENT':
      return {
        ...state,
        selectedDocument: action.payload,
      }
    case 'SET_LOADING':
      return {
        ...state,
        isLoading: action.payload,
      }
    case 'SET_IS_RESPONSE_JUDGMENT_AI':
      return {
        ...state,
        isResponseJudgmentAi: action.payload,
      }
    case 'SET_ACTIVE_MCQS':
      return {
        ...state,
        activeMcqs: action.payload,
      }
    case 'SET_SELECT_CHAT_ID':
      return {
        ...state,
        selectChatID: action.payload,
      }
    case 'SET_CHAT_HISTORY':
      return {
        ...state,
        chatHistory: action.payload,
      }
    case 'TOGGLE_DARK_MODE':
      return {
        ...state,
        themeToggle: !state.themeToggle,
      }
    case 'SET_SYSTEM_THEME': {
      const isDarkMode = action.payload === 'dark'
      document.documentElement.classList.toggle('dark', isDarkMode)
      return {
        ...state,
        themeToggle: isDarkMode,
      }
    }
    case 'SET_AUTH_USER':
      return {
        ...state,
        authUser: action.payload,
      }
    case 'SET_PREVIEW_DATA':
      return {
        ...state,
        previewData: action.payload,
      }
    case 'SET_STATES':
      return {
        ...state,
        states: action.payload,
      }
    case 'SET_SUBSCRIPTION_ACTIVE':
      return {
        ...state,
        isSubscriptionActive: action.payload,
      }
    case 'SET_ADVANCED_SEARCH_CRITERIA':
      return {
        ...state,
        advancedSearchCriteria: action.payload,
      }
    case 'CLEAR_ADVANCED_SEARCH_CRITERIA':
      return {
        ...state,
        advancedSearchCriteria: null,
      }
    case 'SET_IS_SEARCH_ACTIVE':
      return {
        ...state,
        isSearchActive: action.payload,
      }
    case 'SET_LAST_SEARCH':
      return {
        ...state,
        lastSearch: action.payload,
      }
    case 'SET_ADVANCE_QUERY':
      return {
        ...state,
        advanceQuery: action.payload,
      }
    case 'SET_IS_CHAT_DATA_LOADING':
      return {
        ...state,
        isChatDataLoading: action.payload,
      }
    case 'SET_USER_QUERY_SUBMITTED':
      return {
        ...state,
        userQuerySubmitted: action.payload,
      }
    case 'SET_TOTAL_JUDGEMENTS':
      return {
        ...state,
        totalJudgements: action.payload,
      }
    case 'SET_PAGE_SIZE':
      return {
        ...state,
        pageSize: action.payload,
      }
    case 'SET_CURRENT_PAGE':
      return {
        ...state,
        currentPage: action.payload,
      }

    case 'SET_RECAPTCHA_TOKEN':
      return {
        ...state,
        recaptchaToken: action.payload,
      }
    case 'CLEAR_RECAPTCHA_TOKEN':
      return {
        ...state,
        recaptchaToken: '',
      }
    case 'SET_IS_ON_TRIAL':
      return {
        ...state,
        isOnTrial: action.payload, // payload should be a boolean
      }
    case 'LOGOUT':
      Cookies.remove('token')
      Cookies.remove('refresh')
      return {
        ...state,
        userInfo: null,
      }
    case 'SET_JUDGMENT_SOCKET_CONNECTING':
      return {
        ...state,
        isJudgmentSocketConnecting: action.payload,
      }
    case 'SET_PLANS':
      return {
        ...state,
        plans: action.payload,
      }

    default:
      return state
  }
}

export default Auth
