import React, { useContext , useEffect} from 'react';
import GlobalContext from '../context/GlobalContext';
import { searchBar } from '../staticData/icons';

function SearchQuery() {
  const { sQuery, setSearchPost,selectedTab } = useContext(GlobalContext);

  const handleSearch = (e) => {
    e.preventDefault();
    // Your search logic goes here, you can use the 'query' value.
    // console.log('Searching for:', sQuery);
  };
  useEffect(() => {
    setSearchPost(''); // Reset the search query to empty string when the tab changes
  }, [selectedTab]);

  return (
    <div>
      <div className="search-container">
      <form className="flex border-2 border-[#D9D9D9] rounded-full overflow-hidden justify-center">
        <input
          type="text"
          className="search-input px-3 py-2 md:w-96 xs:w-44 outline-none"
          placeholder="Search..."
          value={sQuery}
          onChange={(e) => setSearchPost(e.target.value)}
        />
        <button
          type="submit"
          className="flex items-center justify-center px-4 border-l bg-[#D9D9D9] "
          onClick={handleSearch}
        >
          {searchBar}
        </button>
      </form>
    </div>
    </div>
  );
}

export default SearchQuery;




