import React, { useContext, useState, useEffect, useRef } from 'react';
import { SocketContext } from '../context/SocketContext';
import searchOptions from './shared/SearchOptions.json';  // Adjust the path as necessary
import "../styles/AdvanceSearch.css"
import Select from 'react-select';
import GlobalContext from '../context/GlobalContext';
import Cookies from "js-cookie";

const AdvancedSearchComponent = (props) => {
    const { sendJudgmentAdvancedSearch } = useContext(SocketContext);
    const [selectedYear, setSelectedYear] = useState('');
    const [selectedJournal, setSelectedJournal] = useState('');
    const [query, setQuery] = useState('');
    const { setAdvancedSearchCriteria,
        isSearchActive,
        setLastSearch,
        setIsSearchActive,
        setAdvanceQuery,
        advanceQuery,
        setStoredQuery,
        setJudgementChatData,
        clearJudgementChatData,
        setQuerySubmission,
        userQuerySubmitted,
        pageSize,
        advancedSearchCriteria,
        currentPage,
        setCurrentPage,
    } = useContext(GlobalContext);
    const previousQuery = useRef(advanceQuery); // Use ref to store the previous query state

    useEffect(() => {
        if (advanceQuery !== previousQuery.current) {
            setCurrentPage(1);
            previousQuery.current = advanceQuery; // Update previous query ref
        }
    }, [advanceQuery, setCurrentPage]);


    const yearOptions = searchOptions.years.map(year => ({ value: year, label: year }));
    const journalOptions = searchOptions.journals.map(journal => ({ value: journal, label: journal }));

    const handleSearch = (e) => {
        e.preventDefault();  // Only call preventDefault if an event object is present
        clearJudgementChatData();
        const trimmedQuery = advanceQuery.trim();
        let messageContent = "Advanced Search: ";

        // Building the message content string based on what fields are filled
        if (trimmedQuery) messageContent += `Query: ${trimmedQuery}`;
        if (selectedYear) messageContent += (messageContent.length > 18 ? ", " : "") + `Year: ${selectedYear}`;
        if (selectedJournal) messageContent += (messageContent.length > 18 ? ", " : "") + `Journal: ${selectedJournal}`;

        // Setting the user message to display the search parameters
        if (trimmedQuery && selectedYear || selectedJournal) {
            setJudgementChatData([{ role: "user", content: messageContent }]);
            props.setStoredQuery(trimmedQuery);
            Cookies.set("query", trimmedQuery);
            setAdvanceQuery("");
            setQuerySubmission(true)

            const searchDetails = {
                command: "advance_search",
                query: trimmedQuery,
                journal: selectedJournal,
                year: selectedYear,
                page: 1, // default to the first page
                page_size: 10 // default page size
            };


            sendJudgmentAdvancedSearch(searchDetails);
            setIsSearchActive(true);
            setLastSearch(searchDetails);
            setAdvancedSearchCriteria({ command: "advance_search", query: trimmedQuery, journal: selectedJournal, year: selectedYear });
         
        } else {
            alert("Please fill at least one field before searching.");
        }
    };
    // useEffect(() => {
    //     if (advanceQuery && userQuerySubmitted) {
    //         handleSearch();  // Call the submit function when the query is set and the user has submitted it
    //     }
    // }, [advanceQuery, userQuerySubmitted]);
    const trimmedQuery = advanceQuery.trim();
    const isDisabled = !trimmedQuery;
    return (
        <div className="block md:ml-[14px] xs:ml-0 w-[92%]">
            <div className=" flex p-2 rounded-lg shadow-lg ">
                <form onSubmit={handleSearch} className="xs:block md:flex w-full">
                    <div className="md:flex xs:block justify-between items-stretch w-full space-x-4">
                        <div className="xs:block md:flex flex-grow border-1 xs:mb-2 xs:ml-[15px] md:ml-0 ">
                            <input
                                type="text"
                                className="flex-grow pl-4 xs:h-12 md:h-9 w-full"
                                placeholder="Keyword Search..."
                                value={advanceQuery}
                                onChange={(e) => setAdvanceQuery(e.target.value)}
                            />

                        </div>
                        <Select
                            className="flex-1 ml-2"
                            value={yearOptions.find(option => option.value === selectedYear)}
                            onChange={(option) => setSelectedYear(option ? option.value : '')}
                            options={yearOptions}
                            placeholder="Select Year"
                            isClearable={true}
                        />
                        <Select
                            className="flex-1 ml-2"
                            value={journalOptions.find(option => option.value === selectedJournal)}
                            onChange={(option) => setSelectedJournal(option ? option.value : '')}
                            options={journalOptions}
                            placeholder="Select Journal"
                            isClearable={true}
                        />
                    </div>
                    <div className="my-btn ml-4 md:mt-0 xs:mt-2">
                        <button
                            type="submit"
                            className={`py-2 px-[28px] text-white rounded-lg ${isDisabled ? 'bg-gray-400 cursor-not-allowed' : 'bg-[#227487] hover:bg-[#56a3b1] cursor-pointer'}`}
                            disabled={isDisabled} // Disable button if trimmedQuery is empty
                        >
                            Apply
                        </button>
                    </div>
                </form>

            </div>

        </div>
    );

};

export default AdvancedSearchComponent;
