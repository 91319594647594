
import React, { Fragment, useState, useContext } from "react";
// import google from "../../assets/google.png";
// import arrow from "../../assets/arrow-1.png";
import bg_image from "../../assets/bg-hero.jpg";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
// import { useGoogleLogin } from "@react-oauth/google";
import ErrorMessages from "../../staticData/errors.json";
import Input from "../../components/shared/Input";
import { Dialog, Transition } from "@headlessui/react";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import FormErrors from "../../components/shared/FormErrors";
import SubmitButtonLogin from "../../components/shared/SubmitButtonLogin";
import { LexaLogo } from "../../staticData/icons";
import { logEvent } from "firebase/analytics";
import { analytics } from '../../components/firebaseAnalytics/Firebase';
import ReCAPTCHA from "react-google-recaptcha";
import GlobalContext from '../../context/GlobalContext'
import { Api } from "../../api";

let EMAIL_REGX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{3})+$/;

const LoginSchema = Yup.object({
  email: Yup.string()
    .required("Please enter your email")
    .max(50, "Email must not exceed 50 characters")
    .matches(EMAIL_REGX, "Invalid email address"),
  password: Yup.string()
    .required("Please enter your password")
    .max(50, "Password must not exceed 50 characters"),
});

function Login() {
  const { recaptchaToken, setRecaptchaToken, setIsOnTrial } = useContext(GlobalContext);
  const Navigate = useNavigate();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  // const key = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
  const key = process.env.REACT_APP_RECAPTCHA_KEY
  const queryParams = new URLSearchParams(location.search);
  const redirect = queryParams.get("redirect");

  // const email_user = Cookies.get("email");

  const initialValues = {
    email: "",
    password: "",
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: LoginSchema,
      onSubmit: async (values, action) => {
        if (!recaptchaToken) {
          Swal.fire({
            icon: 'error',
            title: 'Please verify you are not a robot',
          });
          return;
        }
        setLoading(true);
        await Api.login(values)

          .then((response) => {
            Cookies.set("token", response?.data?.data?.token?.access);
            Cookies.set("refresh", response?.data?.data?.token?.refresh);
            Cookies.set("trail", response?.data?.data?.subscription?.on_trial);
            Cookies.set("subscription", response?.data?.data?.subscription?.subscription);
            Cookies.set("subscriptionPlan",response?.data?.data?.profile?.subscription_plan?.for_student);
            const userEmail = response?.data?.data?.profile?.email;
            const trial = response?.data?.data?.subscription?.on_trial
            setIsOnTrial(trial);
            logEvent(analytics, 'login', {
              method: 'EmailAndPassword',
              route_name: window.location.pathname,
              username: userEmail,
            });
            Cookies.set("email", values.email);
            if (redirect === "payment") {
              window.location.href = "/payment";
            } else {
              window.location.href = "/";
            }

            setLoading(false);
            action.resetForm();

          })
          .catch((error) => {
            setLoading(false);

            logEvent(analytics, 'login_failure', {
              error: "Login Failed", // A generic message instead of error details

            });


            // Call Swal.fire and handle the promise it returns
            Swal.fire({
              icon: "error",
              title: 'Oops...',
              text: error.response.data.message || "An unexpected error occurred",
              customClass: {
                confirmButton: "custom-confirm-button",
              },
            }).then((response) => {

              if (error.response.data.message === "User is not verified. Please verify OTP through signup")
                Navigate(`/verify-otp/${values?.email}`)
              else
                console.log("error", error.response.data.message)
            })
          });
      },
    });
  const onChangeRecaptcha = token => {
    setRecaptchaToken(token);
  };

  return (
    <>
      <div
        className="max-w-screen h-[100vh] bg-no-repeat bg-cover bg-center flex justify-center items-center"
        style={{ backgroundImage: `url(${bg_image})` }}
      >
        <div
          className=" login_box bg-transparent xs:w-[95%] sm:w-[75%] md:w-[60%] lg:w-[45%] xl:w-[35%] 2xl:w-[40%] 3xl:w-[40%] h-[95vh] flex flex-col md:py-[10px] 2xl:py-[20px] justify-between items-center
       
          rounded-[54px] border-r-2 border-l-2 border-[#0c1c1e] shadow-[0_0px_4px_0px_rgba(0,0,0,0.25)] overflow-auto"
        >
          <div className="w-full flex flex-col items-center gap-[40px]">
            <div className=" w-[210px] md:w-[100%] mt-[15px] gap-3 flex flex-col items-center justify-center md:h-[75px] 2xl:w-auto 2xl:h-auto 3xl:w-[500px] 4xl:w-[800px] 4xl:mt-10">
              {LexaLogo}
            </div>
            <form
              onSubmit={handleSubmit}
              className="xs:w-[90%] sm:w-[80%] md:w-[70%] lg:w-[70%] xl:w-[65%] 2xl:w-[60%]"
            >
              <h1 className="text-center mt-[3vh] mb-[2vh] md:text-[3.5vh] 2xl:text-[3vh] font-[500] font-[Poppins] text-white">
                Sign in
              </h1>
              <div className="xs:mb-5 md:mt-2 2xl:mt-3 3xl:mb-5">
                <Input
                  type="text"
                  placeholder="Enter Your Email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  errors={errors.email}
                  text="Email"
                />
                <p className="mb-0 text-sm">
                  {errors.email && touched.email ? (
                    <FormErrors error={errors.email} />
                  ) : null}
                </p>
              </div>
              <div className="md:mt-4 2xl:mt-7 3xl:mb-5">
                <div className="w-full relative">
                  <Input
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter Your Password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={errors.password}
                    text="Password"
                  />
                 {showPassword ? (
                      <AiFillEyeInvisible
                        onClick={() => setShowPassword(false)}
                        className='absolute right-3 cursor-pointer text-gray-400 text-sm'
                        style={{ top: '50%', transform: 'translateY(-50%)' }}
                      />
                    ) : (
                      <AiFillEye
                        onClick={() => setShowPassword(true)}
                        className='absolute right-3 cursor-pointer text-gray-400 text-sm'
                        style={{ top: '50%', transform: 'translateY(-50%)' }}
                      />
                    )}
                  <p className="mb-0 text-sm">
                    {errors.password && touched.password ? (
                      <FormErrors error={errors.password} />
                    ) : null}
                  </p>
                </div>
                <div className="flex items-center justify-between mb-0 mt-1">
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      className="cursor-pointer w-[17px] h-[17px] 3xl:w-[20px] 3xl:h-[20px] 4xl:w-[35px] 4xl:h-[35px]"
                    />
                    <span className="mb-0 ms-2 text-[13px] text-white 3xl:text-2xl 4xl:text-[24px]">
                      Remember me
                    </span>
                  </div>
                  <p
                    className="mb-0 mt-1 3xl:text-xl 4xl:text-[24px] text-[13px] text-[#1F88FE] font-[500] cursor-pointer"
                    onClick={() => Navigate("/forgotPassword")}
                  >
                    Forgot Passsword?
                  </p>
                </div>
              </div>
              <div className="mt-3">
                <ReCAPTCHA
                  sitekey={key}
                  onChange={onChangeRecaptcha}
                />
              </div>

              <div className="flex justify-end mt-4">
                <SubmitButtonLogin
                  title="Log in"
                  handleSubmit={handleSubmit}
                  loading={loading}
                />
              </div>
            </form>
            {/* <div className="xs:w-[90%] sm:w-[80%] md:w-[70%] lg:w-[70%] xl:w-[65%] 2xl:w-[60%] md:mt-[25px] 2xl:mt-5 d-flex justify-center items-center">
              <div className="bg-gray-400 w-[45%] h-[1px]"></div>
              <div className="w-[10%] border-[1px] border-[#9d9d9d] rounded-[5px] text-center 3xl:text-2xl 4xl:text-[35px] 4xl:py-[10px]">
                or
              </div>
              <div className="bg-gray-400 w-[45%] h-[1px]"></div>
            </div>
            <button
              className="xs:w-[90%] xs:h-14 sm:w-[80%] md:w-[70%] lg:w-[70%] xl:w-[65%] 2xl:w-[60%] md:h-[50px] 2xl:h-[60px] 3xl:h-[80px] 4xl:h-[120px] mt-[-15px] bg-[#FCFCF9] shadow-[0_1px_6px_0px_rgba(0,0,0,0.10)] px-[20px] 4xl:px-[40px] 4xl:mt-[50px] border-[1px] border-[#8686867c]  rounded-[10px] flex justify-between items-center"
              onClick={() => googleLogin()}
            >
              <div className="flex items-center">
                <img
                  src={google}
                  alt=""
                  className="4xl:w-[60px] 4xl:me-2 md:w-[20px] 2xl:w-[30px]"
                />
                <p className="mb-0 ms-3 3xl:text-2xl 4xl:text-[40px]">
                  Continue With Google
                </p>
              </div>
              <img
                src={arrow}
                alt=""
                className="4xl:w-[60px] md:w-[20px] 2xl:w-[30px]"
              />
            </button> */}
          </div>
          <div className=" text-white xs:py-[10px] xs:px-[50px] text-[9px] xs:text-[9px] md:text-[10px] 4xl:mb-5">
            If you do not have an account yet,{" "}
            <Link
              to="/register"
              className="font-semibold text-decoration-none text-[#1f88fe]"
            >
              Create Account
            </Link>{" "}
            to get started!
          </div>
        </div>
      </div>
      <ForgotPasswordModal show={show} setShow={setShow} />
    </>
  );
}

export default Login;

const ForgotSchema = Yup.object({
  email: Yup.string()
    .required("Please enter your email")
    .matches(EMAIL_REGX, "Invalid email address")
    .max(50, "Email must not exceed 50 characters"),
});
function ForgotPasswordModal(props) {
  const cancelButtonRef = React.useRef(null);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(false);
  const initialValues = {
    email: "",
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: ForgotSchema,
      onSubmit: async (values, action) => {
        setLoading(true);
        await axios
          .post(`${BASE_URL}/user/send-reset-password-email/`, {
            email: values.email,
          })
          .then((response) => {
            action.resetForm();
            props.setShow(false);
            setLoading(false);
            Swal.fire({
              icon: "success",
              text: response.data.msg,
              customClass: {
                confirmButton: "custom-confirm-button",
              },
            });
          })
          .catch((error) => {
            setLoading(false);
            const errorMessage = getErrorMessage(error.response.status);
            if (errorMessage) {

              Swal.fire({
                icon: "error",
                text: errorMessage,
                customClass: {
                  confirmButton: "custom-confirm-button",
                },
              });
            }
          });
      },
    });

  return (
    <>
      <Transition.Root show={props.show} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => props.setShow(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className=" flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl px-4 py-4 transition-all xl:w-[35%] 3xl:w-[30%]">
                  <div className="w-full bg-white flex flex-col items-center ">
                    <Dialog.Title
                      as="h1"
                      className=" mb-[20px] text-3xl 3xl:text-6xl 4xl:text-[100px] 4xl:mb-[30px]"
                    >
                      Forgot Password
                    </Dialog.Title>
                    <div className="mt-2 w-full">
                      <Input
                        type="text"
                        placeholder="Enter Your Email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errors={errors.email}
                      />
                      <p>
                        {errors.email && touched.email ? (
                          <small className="text-red-600 3xl:text-xl 4xl:text-[35px]">
                            {errors.email}
                          </small>
                        ) : null}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center justify-end py-3">
                    <button
                      type="button"
                      onClick={() => props.setShow(false)}
                      ref={cancelButtonRef}
                      className="me-2 outline-none flex justify-center items-center bg-gray-400 md:w-[120px] 2xl:w-[130px] h-[40px] 2xl:h-[50px] 3xl:w-[180px] 3xl:h-[65px] 4xl:w-[280px] 4xl:h-[120px] 3xl:text-2xl 4xl:text-[35px] rounded-[150px] text-white font-medium text-[17px]"
                    >
                      Cancel
                    </button>
                    <SubmitButtonLogin
                      title="Submit"
                      handleSubmit={handleSubmit}
                      loading={loading}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

function getErrorMessage(statusCode) {
  const errorItem = ErrorMessages.find((item) => item[statusCode.toString()]);
  return errorItem
    ? errorItem[statusCode.toString()]
    : "An unknown error occurred.";
}
