import React, { useContext, useState, useEffect } from "react";
import "../styles/Navbar.css";
import Sidebar from "./Sidebar";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { lexa_logo_home } from "../staticData/icons";
import GlobalContext from "../context/GlobalContext";
import { Link, useNavigate, useLocation } from "react-router-dom";
import tabs from "../staticData/navbarTabs.json";


function Navbar() {
  const [showTabs, setShowTabs] = useState(false);
  const [showBlogTabs, setShowBlogTabs] = useState(false);
  const [show, setShow] = useState(false);
  const { selectedTab, setSelectedTab, setIsChatDataLoading, themeToggle, toggleDarkMode } = useContext(GlobalContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [navbarBg, setNavbarBg] = useState('');
  const [textColor, setTextColor] = useState('');
  const [showSidebar, setShowSidebar] = useState(false); // Corrected typo

  const [lastChatId, setLastChatId] = useState('');

  useEffect(() => {
    // Define the paths where tabs should not be shown
    const pathsWithoutTabs = ["/payment", "/payment-history"];
    setShowTabs(!pathsWithoutTabs.includes(location.pathname));

    // Check if the current page is the chatbot with a chat ID
    if (location.pathname.startsWith("/ChatBot/")) {
      const pathSegments = location.pathname.split("/");
      const chatId = pathSegments[2]; // Assumes the chat ID is after "/chatbot/"
      if (chatId) {
        setLastChatId(chatId);
      }
    }
    if (location.pathname.startsWith("/chatbot")) {
      setIsChatDataLoading(false);
    }
  }, [setIsChatDataLoading]);


  useEffect(() => {
    const path = location.pathname.slice(1).toLowerCase();
    const defaultTab = 'chatbot';
    const tabPath = path === '' ? defaultTab : path;
    const matchedTab = tabs.find(tab => tab.tab_ref.replace(/\s+/g, '').toLowerCase() === tabPath);
    setSelectedTab(matchedTab ? matchedTab.tab_ref : 'ChatBot');
  }, [location]);

  useEffect(() => {
    // Use the location.pathname to determine the current path
    const currentPath = location.pathname;
    const isBlogRelatedPath = currentPath === "/blogs" || currentPath.startsWith("/edit-new-post/") || currentPath === "/add-new-post" || currentPath === "/preview-page";
    const isPaymentRelatedPath = currentPath === "/payment" || currentPath === "/payment-history";

    if (isPaymentRelatedPath) {


      setShowTabs(false);
      setShowSidebar(true);
      setNavbarBg('');
    } else if (isBlogRelatedPath || currentPath.match(/^\/blogs\/.+$/)) {
      setShowTabs(false);
      setNavbarBg('bg-[#0b161a]');
      setShowSidebar(false);
      setTextColor('text-white')
    }
    else {
      setShowTabs(true);
      setNavbarBg(''); // Default background or any other you prefer
      setShowSidebar(true); // Ensure sidebar is shown for all other paths
    }
  }, [location.pathname,showTabs]);


  const toggleSidebar = () => {
    setShow(prev => !prev);
  };

  const handleTabClick = (tab) => {
    const tabPath = tab.replace(/\s+/g, '').toLowerCase();
    setSelectedTab(tab);
    if (tab === 'ChatBot' && lastChatId) {
      navigate(`/ChatBot/${lastChatId}`);
    } else {
      navigate(`/${tabPath}`);
    }
  };
  const handleNavigationToLogin = () => {
    navigate('/login')
  }
  const handleNavigationToSignup = () => {
    navigate('/register')
  }



  return (
    <>
      <nav className={`flex justify-between w-[100%] ${navbarBg} ${themeToggle ? 'dark' : ''} dark:bg-[#1d1b1d]`}>
        <div className="logo">
          {show ? (
            <CloseIcon className="icon dark:text-white" onClick={toggleSidebar} />
          ) : (
            <MenuIcon className="icon dark:text-white" onClick={toggleSidebar} />
          )}
          <Link className=" items-start justify-start flex w-[50%]" to={"/"}>
            {lexa_logo_home}
          </Link>
          <span className={`text-sm mt-3 ml-[-2px] ${textColor} dark:text-white`}>(2.0)</span>
        </div>
        {showTabs && (
          <div className="md:flex xs:hidden items-end justify-start gap-3 lg:w-[55%] md:w-[62%] h-full">
            {tabs.map((item, index) => (
              <div
                className={`w-[120px] 2xl:w-[180px] 5xl:w-[150px] flex items-center justify-center py-[10px] cursor-pointer ${selectedTab === item.tab_ref
                  ? themeToggle
                    ? "selected-tab-dark"
                    : "selected-tab-light"
                  : themeToggle
                    ? "non-selected-tab-dark"
                    : "non-selected-tab-light"
                  }`} key={index}
                onClick={() => {
                  handleTabClick(item.tab_ref);
                }}
                style={{
                  paddingTop: selectedTab === item.tab_ref && "15px",
                  borderRadius:
                    selectedTab === item.tab_ref
                      ? "10px 10px 0px 0px"
                      : "10px 10px 0px 0px",
                  borderTop:
                    selectedTab === item.tab_ref
                      ? "1px solid #CEE9FF"
                      : "none",
                  borderLeft:
                    selectedTab === item.tab_ref
                      ? "1px solid #CEE9FF"
                      : "none",
                  borderRight:
                    selectedTab === item.tab_ref
                      ? "1px solid #CEE9FF"
                      : "none",
                  boxShadow:
                    selectedTab === item.tab_ref
                      ? "none"
                      : "0px 0px 6px 1px rgba(0, 0, 0, 0.09)",
                  zIndex: selectedTab === item.tab_ref ? "500" : "1",
                  position: "relative",
                  bottom: selectedTab === item.tab_ref ? "-1px" : "0px ",
                }}
              >
                <span
                  className={`font-[Roboto] text-[16px] 2xl:text-[20px] text-[#13343B] dark:text-white ${selectedTab === item.tab_ref ? "font-bold" : "font-normal"
                    } leading-normal opacity-[70%]`}
                >
                  {item.tab_name}
                </span>
              </div>
            ))}
          </div>
        )}
        {showBlogTabs && (
          <div className="flex">
            <div className="m-4">
              <button onClick={handleNavigationToLogin} className=" m-2 px-[33px] py-[5px] text-md text-center font-semibold bg-transparent text-white border-1 hover:bg-gray-100 rounded-2xl" href="login">Sign in</button>
              <button onClick={handleNavigationToSignup} className=" px-[33px] py-[5px] text-md text-center text-white font-semibold bg-transparent border-1 hover:bg-blue-700 rounded-2xl" href="singup">Sign Up</button>
            </div>
          </div>
        )}
      </nav>


      {show && <Sidebar show={show} setShow={setShow} />}
    </>
  );
}

export default Navbar;
