import React, { useState, useEffect, useRef, useContext } from "react";
import { Api } from "../../api";
import ResultQuiz from "./ResultQuiz";
import Cookies from "js-cookie";
import Loader from "./Loader";
import GlobalContext from '../../context/GlobalContext';


function QuizData() {
  const quizDataRef = useRef(null);
  const {
    selectedCategory,
    quizCorrectAnswersCount,
    setCorrectAnswersCount,
    quizAttemptedQuestions,
    setAttemptedQuestions,
    themeToggle
  } = useContext(GlobalContext);
  const [quizData, setQuizData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [userSelections, setUserSelections] = useState({});
  const [show, setShow] = useState(false);
  const [viewFeedback, setViewFeedback] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(selectedCategory);

  useEffect(() => {
    const token = Cookies.get("token");

    if (token) {
      setIsLoading(true);
      Api.quiz(token, selectedCategory).then((res) => {
        setUserSelections({})
        setCorrectAnswersCount(0)
        setAttemptedQuestions(0)

        setCurrentCategory(selectedCategory);
        setViewFeedback(false);
        setIsLoading(false)
        if (res.status === 200 && res?.data && res?.data?.data?.length > 0) {
          setQuizData(res?.data?.data);
        }
      }).catch((err) => {
        setIsLoading(false)
        // console.log("Error fetching quiz data:", err);
      });
    }
  }, [selectedCategory]);

  const fixSpaceInText = (text) => {
    // Use regular expression to add a space before capital letters that follow lowercase letters
    return text.replace(/([a-z])([A-Z])/g, "$1 $2");

  };

  const handleSelected = (selectedOption, correctAnswer, questionNumber) => {
    if (viewFeedback) {
      // Prevent selection if feedback has been viewed
      return;
    }

    if (!show) {
      // Only allow selection if the results haven't been generated yet
      const isNewAttempt = !(questionNumber in userSelections);
      setUserSelections(prevSelections => ({
        ...prevSelections,
        [questionNumber]: selectedOption,
      }));

      if (isNewAttempt) {
        setAttemptedQuestions(quizAttemptedQuestions + 1);
        if (selectedOption === correctAnswer) {
          setCorrectAnswersCount(quizCorrectAnswersCount + 1);
        }
      } else {
        if (userSelections[questionNumber] !== correctAnswer && selectedOption === correctAnswer) {
          setCorrectAnswersCount(quizCorrectAnswersCount + 1);
        } else if (userSelections[questionNumber] === correctAnswer && selectedOption !== correctAnswer) {
          setCorrectAnswersCount(Math.max(quizCorrectAnswersCount - 1, 0));
        }
      }
    }
  };


  const handleViewFeedback = () => {
    // Close the modal first
    setShow(false);
    // console.log("attemptedQuestions",)
    setTimeout(() => {
      setViewFeedback(true);
      quizDataRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 300); // Adjust the timeout as needed
  };


  const resetQuiz = () => {
    setUserSelections({});
    setCorrectAnswersCount(0);
    setShow(false);
    setViewFeedback(false);
    setAttemptedQuestions(0);
  };

  const handleQuestion = () => {
    setShow(true);
  };



  if (isLoading) {
    return (
      <div className="fixed inset-0 bg-white bg-opacity-50"> {/* Optionally add a semi-transparent white background */}
        <Loader />
      </div>
    );
  }

  return (
    <div className="content-wrapper dark:bg-[#232123] static" ref={quizDataRef}>
      {show ? (
        <ResultQuiz
          correctAnswer={quizCorrectAnswersCount} // from global state
          totalQuestions={quizData.length}
          resultPercentage={(quizCorrectAnswersCount / quizData.length * 100).toFixed(2)} // calculated from global state
          attemptedQuestions={quizAttemptedQuestions} // from global state
          showModal={show}
          handleCloseModal={resetQuiz}
          handleViewFeedback={handleViewFeedback}
        />
      ) : (
        <div className="quiz-wrapper md:ml-0 xs:ml-[18px]">
          {quizData.map((question, index) => (
            <div key={index} className="quizData mt-4 mb-[60px]">
              <div className="quiz-Question md:ml-6 xs:ml-0">
                <h4 className="font-bold text-lg capitalize dark:text-white">
                  Question: {index + 1}
                </h4>
                <div className="question text-lg space-y-2 sx:text-[16px] dark:text-white md:text-[18px]">
                  <p>{fixSpaceInText(question.questionText)}</p>
                </div>
              </div>
              {question.options.map((option, i) => (
                <button
                  key={i}
                  onClick={() => handleSelected(
                    option.id,
                    question.correctAnswer,
                    index
                  )}
                  className={`
                  quiz-answer 
                  ${viewFeedback
                    ? (option.id === question.correctAnswer
                        ? "bg-green-500" // Correct answer
                        : (userSelections[index] === option.id
                            ? "bg-red-500" // Incorrectly selected option
                            : "bg-[#f4f3f6]" // Unselected option
                          )
                      )
                    : (userSelections[index] === option.id
                        ? "bg-[#192D31] text-white" // Selected option before feedback
                        : "bg-[#f4f3f6]" // Unselected option before feedback
                      )
                  } inline-block rounded py-2 pl-4 pr-6 my-2 md:ml-6 xs:ml-[6px] md:min-w-[470px] xs:min-w-[280px]`
                }
              >
                  <div className="quiz-option flex items-center">
                    <span className="tag font-semibold text-xl bg-[#dddada] capitalize text-black rounded-full bg-tag-color px-3 py-2 md:text-[18px] xs:text-[16px]">
                      {option.id}
                    </span>
                    <div className="left ml-2 mt-3 pr-3">
                      <p className="capitalize font-semibold pr-3 xs:text-[14px] md:text-[18px] max-w-17 whitespace-normal">
                        {fixSpaceInText(option.value)}
                      </p>
                    </div>
                  </div>
                </button>
              ))}
            </div>
          ))}
          <div className={`fixed bottom-0 md:left-[285px] xs:left-0 inset-x-0 px-4 py-3 shadow-inner flex justify-end z-10 quiz-button-container ${themeToggle ? 'quiz-button-container-dark' : 'quiz-button-container-light'}`}>

          {/* <div className="fixed bottom-0 md:left-[260px] xs:left-0 inset-x-0 px-4 py-3 bg-white dark:bg-[#232123] shadow-inner flex justify-end z-10"> */}
            <button
              type="submit"
              className="bg-[#4FAE52] text-white text-lg font-semibold px-6 py-2 rounded-md capitalize"
              onClick={handleQuestion}
            >
              Generate Result
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default QuizData;
