import React, {
  useContext,
  useState,
  useRef,
  useEffect,
  useCallback,
} from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Cookies from 'js-cookie'
import GlobalContext from '../context/GlobalContext'
import '../styles/ChatComponent.css'
import { messageIcon, newChat } from '../staticData/icons'
import { MdModeEdit } from 'react-icons/md'
import { RiDeleteBin6Line } from 'react-icons/ri'
import {
  SocketContext,
  socketMessageCommands,
} from '../context/SocketContext.jsx'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

function useOutsideClick(ref, callback) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [callback])
}

function CustomDropdown({ dropdownVisible, onEdit, onDelete, innerRef }) {
  return (
    <>
      {dropdownVisible && (
        <div className='dropdown-menu2 z-50' ref={innerRef}>
          <div
            className='border-b-[0.6px] border-[#476788] py-2  flex w-full px-6 items-center justify-between hover:bg-[#f3f3ee]'
            onClick={onEdit}
          >
            <span className='text-[14px] 2xl:text-[16px] text-[#20808D] leading-normal '>
              Edit
            </span>
            <MdModeEdit color='#20808D' className='text-[17px]' />
          </div>
          <div
            className='flex w-full px-6 items-center justify-between py-2 hover:bg-[#f3f3ee]'
            onClick={(e) => {
              e.stopPropagation();
              onDelete()
            }}
          >
            <span className='text-[14px] 2xl:text-[16px] text-[#20808D] leading-normal '>
              Delete
            </span>
            <RiDeleteBin6Line color='#20808D' className='text-[17px]' />
          </div>
        </div>
      )}
    </>
  )
}
function ChatComponent(props) {
  const dropdownRef = useRef(null)

  useOutsideClick(dropdownRef, () => {
    if (activeDropdownIndex !== null) {
      setActiveDropdownIndex(null)
    }
  })

  const [activeDropdownIndex, setActiveDropdownIndex] = useState(null)
  const [isEditing, setIsEditing] = useState(false)
  const [editableChatName, setEditableChatName] = useState('')
  const [editableChatIndex, setEditableChatIndex] = useState(null)
  const { sendSocketCommand } = useContext(SocketContext)
  const {
    clearChatData,
    setErrorMessage,
    setSelectChatID,
    chatHistory,
    setChatHistory,
    isAwaitingResponse,
    setIsAwaitingResponse,
    chatData,
    chatLoading,
    selectChatID,
    setIsLoading,
    isLoading,
    isChatDataLoading,
    setIsChatDataLoading
  } = useContext(GlobalContext)

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (location.pathname === '/') {
      navigate(`/ChatBot`)
    }
  }, [navigate, location])

  const { id } = useParams()
  const [activeItem, setActiveItem] = useState(1)
  const handleLogout = () => {
    Cookies.remove('token')
    Cookies.remove('refresh')
    window.location.href = '/'
  }

  const toggleDropdown = (index, event) => {
    event.stopPropagation()
    setActiveDropdownIndex(activeDropdownIndex === index ? null : index)
  }

  // const handleDelete = (index) => {
  //   sendSocketCommand({
  //     command: socketMessageCommands.deleteChat,
  //     chat_id: chatHistory[index]?.id,
  //   })

  //   const updatedChatHistory = chatHistory.filter((_, i) => i !== index)
  //   setChatHistory(updatedChatHistory)
  //   setActiveDropdownIndex(null)
  // }


  const handleDelete = (index) => {
    const chatId = chatHistory[index]?.id;

    sendSocketCommand({
      command: socketMessageCommands.deleteChat,
      chat_id: chatId,
    });
    const updatedChatHistory = chatHistory.filter((_, i) => i !== index);
    setChatHistory(updatedChatHistory);
    if (id === chatId.toString()) {
      clearChatsData(); // This will clear all chat data and navigate to '/Chat Bot'
      setActiveDropdownIndex(null);
    } else {
      setActiveDropdownIndex(null);
    }
  }


  // Handle chat edit
  const handleEdit = (index) => {
    setEditableChatIndex(index)
    setEditableChatName(chatHistory[index].name)

    setIsEditing(true)
    setActiveDropdownIndex(null)
  }

  // Save edited chat name
  const saveEdit = (index) => {
    const newChatHistory = [...chatHistory]
    if (!newChatHistory[index]) {
      return
    }

    newChatHistory[index].name = editableChatName

    sendSocketCommand({
      command: socketMessageCommands.updateChatName,
      chat_id: newChatHistory[index]?.id,
      name: editableChatName,
    })
    setChatHistory(newChatHistory)
    setIsEditing(false)
  }

  const handleKeyPress = (e, index) => {
    if (e.key === 'Enter') {
      saveEdit(index)
      e.preventDefault()
    }
  }

  const clearChatsData = () => {
    clearChatData()
    setErrorMessage('')
    setSelectChatID(null)
    setActiveItem(undefined)
    navigate({ pathname: '/ChatBot' })
  }

  const handleChatID = useCallback(
    (id) => {
      // Check if the new ID is the same as the currently active one
  
      if (id === activeItem) {
        console.log('This chat is already active.');
        setIsChatDataLoading(false);  // Set loading to false if the same chat is selected
        return;
      }
  
      console.log('Loading new chat data...'); // Log message when loading new chat
      setIsChatDataLoading(true); 
      clearChatData()
      setSelectChatID(id);
      setActiveItem(id);
    },
    [
      clearChatData,
      setErrorMessage,
      setSelectChatID,
      isAwaitingResponse,
      setActiveItem,
      activeItem,  // Include activeItem in the dependency array
    ]
  );
  
  const { id: Id } = useParams()
  useEffect(() => {
    if(chatHistory.length === 0){
      console.log("no chat found")
    }
    
    if (chatHistory && chatHistory.length > 0) {
      setIsChatDataLoading(false);
      setIsLoading(false)
      Id && Id !== 'ChatBot' && handleChatID(Number(Id))
    }
  }, [chatHistory])

  useEffect(() => {
    if ((chatData.length > 0 || chatHistory.length > 0) && isAwaitingResponse) {
      setIsAwaitingResponse(false);
    }
  }, [chatData, chatHistory, isAwaitingResponse]);


  return (
    <div className='sideChat block '>
      <div id='chatHistory' className='dark:bg-[#141618] '>
        <div
          className='w-full px-[15px] flex items-center h-[70px] 2xl:h-[80px]  dark:bg-black   bg-[#3E3E3E4A] border-b-[1px]'
          style={{ borderColor: 'rgba(206, 233, 255, 0.2)' }}
        >
          <span className=' text-[#ffff] font-roboto 2xl:text-[26px] text-[20px]  font-[500] dark:text-white '>
            Chat History
          </span>
        </div>
        <div className='first-box'>
          {isLoading ? (
            <div className='loader'>
              <div
                className='inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]'
                role='status'
              >
                <span className='!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]'>
                  Loading...
                </span>
              </div>
            </div>
          ) : (
            chatHistory.length === 0 ? (
              <div className='no-chat-message text-white text-center justify-center m-auto flex'>
                No chat history available.
              </div>
            ) : (
              <div className=' flex flex-col items-start justify-start py-[20px] gap-3 h-full'>
                {chatHistory.map((item, i) => (
                  <div
                    className='flex w-full items-center justify-start gap-2 relative cursor-pointer'
                    onClick={() => {
                      const options = { pathname: `/ChatBot/${item.id}` }
                      navigate(options, { replace: true })
                      !isAwaitingResponse && handleChatID(item.id)
                    }}
                    style={{
                      pointerEvents: isAwaitingResponse ? 'none' : 'auto',
                    }}
                    key={i}
                  >
                    <div
                      className={`w-[8px] h-[60px] 2xl:h-[65px] ${activeItem === item.id ? 'bg-white' : 'bg-[#FFFFFF38]'
                        }`}
                      style={{
                        borderRadius: '0px 10px 10px 0px',
                        // background: 'rgba(255, 255, 255, 0.85)',
                      }}
                    ></div>
                    <div className='flex flex-col items-start justify-start gap-[10px] 2xl:gap-[5px] w-[90%]'>
                      <span className=' text-[#ffff] font-roboto 2xl:text-[16px] text-[13px]  font-[400] '>
                        {item?.created_at}
                      </span>
                      <div
                        className='text-[#fff] absolute right-0 cursor-pointer'
                        onClick={(e) => toggleDropdown(i, e)}
                      >
                        <MoreVertIcon
                          sx={{
                            color: '#fff',
                          }}
                        />
                      </div>
                      <div className='w-full flex items-center justify-start gap-[5px]'>
                        {messageIcon}
                        {isEditing && editableChatIndex === i ? (
                          <input
                            type='text'
                            value={editableChatName}
                            onChange={(e) => setEditableChatName(e.target.value)}
                            onBlur={() => saveEdit(i)}
                            onKeyPress={(e) => handleKeyPress(e, i)}
                            autoFocus
                          />
                        ) : (
                          <span className='flex text-[#ffff]  font-roboto 2xl:text-[18px] text-[15px]  font-[400]'>
                            {item?.name}
                          </span>
                        )}
                      </div>

                      <CustomDropdown
                        dropdownVisible={activeDropdownIndex === i}
                        setDropdownVisible={() => toggleDropdown(i)}
                        handleLogout={handleLogout}
                        onEdit={() => handleEdit(i)}
                        onDelete={() => handleDelete(i)}
                        innerRef={dropdownRef}
                      />
                    </div>
                  </div>
                ))}
              </div>
            )
          )}
        </div>

        <div className='second-box'>
          <div
            className='w-full flex items-center justify-center px-[20px]  bg-[#3E3E3E4A] dark:bg-black  h-[10vh] border-t-[1px]'
            style={{ borderColor: 'rgba(206, 233, 255, 0.2)' }}
          >
            <div
              onClick={() => clearChatsData()}
              className='w-[100%] rounded-[10px] border-[1px] border-[#192D31] bg-[#20808D] h-[35px] 2xl:h-[50px] flex items-center justify-center cursor-pointer gap-1'
            >
              <div>{newChat}</div>
              <span className=' text-[#ffff] font-roboto 2xl:text-[15px] text-[12px] font-[500] '>
                New Chat
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ChatComponent
