import React, { useEffect, useState, useContext } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import '../../styles/general.css'
import GlobalContext from '../../context/GlobalContext'
import { Viewer } from '@react-pdf-viewer/core'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
import { pdfjs } from 'react-pdf'
import { Worker } from '@react-pdf-viewer/core'
import { searchPlugin } from '@react-pdf-viewer/search';
// Continue importing other necessary modules...

// Import styles of react-pdf-viewer

import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/legacy/build/pdf.worker.min.js',
  import.meta.url
).toString()

const JudgementModel = ({ open, onClose, judgement_data, storedQuery }) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [numPages, setNumPages] = useState(null)
  const [currentSearchIndex, setCurrentSearchIndex] = useState(0)
  const { selectedDocument, setSelectedDocument } = useContext(GlobalContext)
  const [pdfUrl, setPdfUrl] = useState('')
  const defaultLayoutPluginInstance = defaultLayoutPlugin()

  const searchPluginInstance = searchPlugin({
    // Assuming storedQuery is a string of space-separated keywords
    keyword: storedQuery ? storedQuery.split(' ') : []
});




  useEffect(() => {
    if (!open) {
      setSearchTerm('')
      setCurrentSearchIndex(0)
    }
  }, [open])

  useEffect(() => {
    if (selectedDocument) {
      const httpsUrl = convertS3UrlToHttps(selectedDocument.document_url)
      setPdfUrl(httpsUrl)
    }
  }, [selectedDocument])

  // This conversion function doesn't need to be inside a hook
  const convertS3UrlToHttps = (s3Url) => {
    if (!s3Url) {
      console.warn(
        'convertS3UrlToHttps was called with an undefined or null argument'
      )
      return ''
    }
    return s3Url.replace(
      /^s3:\/\/([^\/]+)\/(.*)$/,
      'https://$1.s3.amazonaws.com/$2'
    )
  }

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }

  if (!open) return null

  return (
    <div
      onClick={onClose}
      className='fixed top-0 bottom-0 left-0 right-0 flex items-start justify-center bg-[#767676] bg-opacity-20 backdrop-blur-sm z-[9999] py-10'
    >
      <div
        onClick={(e) => {
          e.stopPropagation()
        }}
        className='fixed flex flex-col w-[70%] h-[90vh]  bg-[#FCFCF9] '
      >
        <div
          className='cursor-pointer w-full flex items-end justify-end pt-2 px-2'
          onClick={onClose}
        >
          <AiOutlineClose size={20} className='text-[#13343B]' />
        </div>
        {pdfUrl && (
          <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js'>
            <Viewer
              fileUrl={pdfUrl}
              plugins={[defaultLayoutPluginInstance,searchPluginInstance]}
              onDocumentLoadSuccess={onDocumentLoadSuccess}
            />
          </Worker>
        )}
      </div>
    </div>
  )
}

export default JudgementModel
