/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from 'react'
import '../styles/general.css'
import send_icon from '../assets/updateSendIcon.png'
import disable_send_icon from '../assets/send-icon-1.png'
import { ThreeDots, Vortex } from 'react-loader-spinner'
import ReconnectingWebSocket from 'reconnecting-websocket'
import GlobalContext from '../context/GlobalContext'
import { Refresh, Stop } from '../staticData/icons'
import Cookies from 'js-cookie'
import {
  SocketContext,
  socketMessageCommands,
} from '../context/SocketContext.jsx'
import { useParams } from 'react-router-dom'

function Input(props) {
  const {
    setChatData,
    setChatLoading,
    setErrorMessage,
    setInputQuery,
    inputQuery,
    setSendBtnLoading,
    sendBtnLoading,
    selectedTab,
    chatData,
    userInfo,
    setChatHistory,
    chatHistory,
    selectChatID,
    setIsAwaitingResponse,
    setSelectedChatId,
  } = useContext(GlobalContext)
  const { sendSocketCommand, sendSocketCommandForHistory } = useContext(SocketContext)

  const { socket } = useContext(SocketContext)
  const [chatID, setChatID] = useState(null)
  const [storedQuery, setStoredQuery] = React.useState(null)
  const [stopDebug, setStopDebug] = useState(true)
  const [generating, setGenerating] = useState(false)
  const SOCKET_BASE_URL = process.env.REACT_APP_BASE_URL_SOCKET
  const webSocket = useRef(ReconnectingWebSocket)
  const token = Cookies.get('token')
  const { id: Id } = useParams()

  useEffect(() => {
    Id && Id !== "ChatBot" && setChatID(Number(Id));
  })

  const ConnectWebSockets = () => {
    webSocket.current.onopen = () => {
      webSocket.current?.send(
        JSON.stringify({
          command: 'all_chats',
        })
      )
    }
        webSocket.current.onmessage = (event) => {
      const data = JSON.parse(event.data)
      if (data.type === 'debug') {
        setGenerating(true)
        setChatLoading(false)
        if (stopDebug) {
          props.setDebugMessage((prevMessage) => prevMessage + data.message)
        }

        setErrorMessage('')
      } else {
        setSendBtnLoading(false)
        setChatLoading(false)
        setGenerating(false)
        if (data.command === 'new_chat') {
          setChatID(data.chat_id)
          setSelectedChatId(data.chat_id)
          const newMessage = { role: 'ai', content: data.new_chat.output }
          setChatData([newMessage])
        } else if (data.command === 'new_message') {
          const newMessage = { role: 'ai', content: data.new_message.output }

          setChatData([newMessage])
        } else if (data.command === 'all_chats') {
          setChatHistory(data.all_chats)
        } else if (data.command === 'fetch_messages') {
        }
        if (stopDebug) {
          props.setDebugMessage('')
        }
        setErrorMessage('')
      }

      if (data.type === 'error') {
        setErrorMessage('an error occured! please try again later')
        setSendBtnLoading(false)
        setGenerating(false)
        setChatLoading(false)
      }
    }

    webSocket.current.onclose = (e) => {}

    webSocket.current.onerror = (err) => {}
  }

  useEffect(() => {
    if (selectChatID) {
      sendSocketCommandForHistory({
        command: socketMessageCommands.fetchMessages,
        chat_id: selectChatID,
      })
    }
  }, [selectChatID])

  const handleSocketSubmit = (e) => {
    e.preventDefault()
    setIsAwaitingResponse(true) //adding this line of state
    setErrorMessage('')
    props.setDebugMessage('')
    setStopDebug(true)
    const trimmedQuery = inputQuery.trim()
    if (trimmedQuery !== '') {
      const messageWithLineBreaks = trimmedQuery.replace(/\n/g, '\n')
      setIsAwaitingResponse(true) //adding this line of state

      if (selectChatID) {
        sendSocketCommand({
          command: socketMessageCommands.newMessage,
          chat_id: selectChatID,
          message: inputQuery,
        })
      } else {
        // new channel
        sendSocketCommand({
          command: socketMessageCommands.newChat,
          name: inputQuery.slice(0, 20) + '...',
          message: inputQuery,
        })
        // append new chat
        sendSocketCommand({ command: socketMessageCommands.allChats })
      }
      setSendBtnLoading(true)
      setChatLoading(true)
      setChatData([{ role: 'user', content: messageWithLineBreaks }])
      setStoredQuery(messageWithLineBreaks)
      setInputQuery('')
    } else {
      setSendBtnLoading(false)
      setChatLoading(false)
    }
  }
  const handleChange = (e) => {
    setInputQuery(e.target.value)
  }
  const autoResize = (e) => {
    e.target.style.height = '24px'
    e.target.style.height = e.target.scrollHeight + 'px'
  }

  const handleStopGenerating = () => {
    setStopDebug(false)
    setSendBtnLoading(false)
    setChatLoading(false)
  }
  const handleGenerateText = (e) => {
    setStopDebug(true)
    setErrorMessage('')
    setSendBtnLoading(true)
    setChatLoading(true)
    props.setDebugMessage('')
    if (chatData.length > 0 && chatData[chatData.length - 1].role === 'ai') {
      chatData.pop()
    }
    webSocket.current?.send(
      JSON.stringify({
        message: storedQuery,
        chat_id: 1,
      })
    )
  }
  return (
    <>
      <form
        id='input-container'
        style={{
          width: selectedTab === 'Judgement' ? '85%' : '96%',
        }}
        className='dark:bg-white dark:text-black'
      >
        <textarea
          className='custom-textarea dark:bg-white text-black outline-none '
          type='text'
          placeholder='Salaam dost, how can I help you...'
          onInput={autoResize}
          value={inputQuery}
          onChange={(e) => handleChange(e)}
          onKeyPress={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.stopPropagation()
              if (inputQuery && !sendBtnLoading) {
                handleSocketSubmit(e)
              }
            }
          }}
          style={{ height: `${!inputQuery && '24px'}` }}
        />
        {sendBtnLoading ? (
          <ThreeDots
            height='30'
            width='30'
            radius='9'
            color='gray'
            ariaLabel='three-dots-loading'
            wrapperStyle={{}}
            wrapperClassName=''
            visible={true}
          />
        ) : (
          <button type='submit'>
            <>
              {inputQuery ? (
                <img
                  src={send_icon}
                  alt=''
                  onClick={
                    inputQuery && !sendBtnLoading
                      ? handleSocketSubmit
                      : (e) => e.preventDefault()
                  }
                />
              ) : (
                <>
                  <img
                    src={disable_send_icon}
                    alt=''
                    onClick={(e) => e.preventDefault()}
                  />
                </>
              )}
            </>
          </button>
        )}
      </form>
    </>
  )
}

export default Input