import React from 'react'
import Arsala from '../../assets/landingPage-imgs/testimonialsImg.png' // Placeholder for testimonial images
import Adeel from '../../assets/landingPage-imgs/testimonialsImg2.png'
import Sarah from '../../assets/landingPage-imgs/testimonialsImg3.png'
import Arsla from '../../assets/landingPage-imgs/testimonialsImg4.png'
import Fatima from '../../assets/landingPage-imgs/testimonialsImg5.png'
import Ahsan from '../../assets/landingPage-imgs/testimonialsImg.png'
import Marquee from 'react-fast-marquee'

const testimonials = [
  {
    name: 'Arslana Nawaz',
    profession: 'Adv. High Court',
    rating: 5,
    body: 'Lexa has been a game-changer for my legal practice. Its AI-powered insights and extensive database of judgments have saved me countless hours of research, allowing me to focus more on client needs. Highly recommend!',
    img: Arsala,
  },
  {
    name: 'Adeel Nawaz',
    profession: 'Law Student',
    rating: 5,
    body: "As a law student, Lexa's MCQ platform has been invaluable in preparing for exams. It's like having a personal tutor guiding me through the complexities of legal principles. A must-have for anyone serious about their legal studies.",
    img: Adeel,
  },
  {
    name: 'Sarah Khan',
    profession: 'Legal Consultant',
    rating: 5,
    body: "Lexa's drafting feature streamlines the process with accuracy and convenience, handling my caseload without a hitch.",
    img: Sarah,
  },
  {
    name: 'Arsla Sidique',
    profession: 'Corporate Lawyer',
    rating: 5,
    body: "Impressed by Lexa's quick responses to my legal queries. Its AI-driven interface delivers clear and authoritative answers, making it my go-to tool for navigating legal complexities with confidence. Highly recommend!",
    img: Arsla,
  },
  {
    name: 'Fatima Akhtar',
    profession: 'Adv. High Court',
    rating: 5,
    body: 'Lexa has transformed how I approach legal research. Its comprehensive database of precedents and analyses provides me with critical insights for formulating winning legal strategies. Exceptional tool!',
    img: Fatima,
  },
  {
    name: 'Ahsan Haq',
    profession: 'Adv. High Court',
    rating: 5,
    body: "Lexa is more than just a chatbot; it's revolutionizing legal consultation in Pakistan. Its capabilities make it an indispensable asset for any legal practice. Truly revolutionary!",
    img: Ahsan,
  },
  {
    name: 'Arslana Nawaz',
    profession: 'Adv. High Court',
    rating: 5,
    body: 'Lexa has been a game-changer for my legal practice. Its AI-powered insights and extensive database of judgments have saved me countless hours of research, allowing me to focus more on client needs. Highly recommend!',
    img: Arsala,
  },
  {
    name: 'Adeel Nawaz',
    profession: 'Law Student',
    rating: 5,
    body: "As a law student, Lexa's MCQ platform has been invaluable in preparing for exams. It's like having a personal tutor guiding me through the complexities of legal principles. A must-have for anyone serious about their legal studies.",
    img: Adeel,
  },
  {
    name: 'Sarah Khan',
    profession: 'Legal Consultant',
    rating: 5,
    body: "Lexa's drafting feature streamlines the process with accuracy and convenience, handling my caseload without a hitch.",
    img: Sarah,
  },
  {
    name: 'Arsla Sidique',
    profession: 'Corporate Lawyer',
    rating: 5,
    body: "Impressed by Lexa's quick responses to my legal queries. Its AI-driven interface delivers clear and authoritative answers, making it my go-to tool for navigating legal complexities with confidence. Highly recommend!",
    img: Arsla,
  },
  {
    name: 'Fatima Akhtar',
    profession: 'Adv. High Court',
    rating: 5,
    body: 'Lexa has transformed how I approach legal research. Its comprehensive database of precedents and analyses provides me with critical insights for formulating winning legal strategies. Exceptional tool!',
    img: Fatima,
  },
  {
    name: 'Ahsan Haq',
    profession: 'Adv. High Court',
    rating: 5,
    body: "Lexa is more than just a chatbot; it's revolutionizing legal consultation in Pakistan. Its capabilities make it an indispensable asset for any legal practice. Truly revolutionary!",
    img: Ahsan,
  },
]

const firstRow = testimonials.slice(0, testimonials.length / 2)
const secondRow = testimonials.slice(testimonials.length / 2)

const ReviewCard = ({ img, name, profession,rating, body }) => {
  return (
    <figure className='review-card text-white' id='Testimonials'>
      <div className='review-header'>
        <img className='review-avatar' src={img} alt={`${name}'s avatar`} />
        <div className='review-user-info'>
          <figcaption className='review-name text-white'>{name}</figcaption>
          <p className='review-username text-white'>{profession}</p>
          <span className='-mt-4'> {'⭐'.repeat(rating)}</span>
        </div>
      </div>
      <blockquote className='review-body text-white'>{body}</blockquote>
    </figure>
  )
}

const Testimonials = () => {
    return (
      <div className='mt-32'>
        <div className='text-center'>
          <h1 className='text-center text-4xl font-bold'>Testimonials</h1>
          <div className='divider border-2 border-[#0D9488] m-auto block w-28'></div>
        </div>
        <div className='marquee-container'>
          <Marquee pauseOnHover gradient={false} speed={80}>
            {firstRow.map((review) => (
              <ReviewCard key={review.username} {...review} />
            ))}
          </Marquee>
          <Marquee
            pauseOnHover
            gradient={false}
            speed={80}
            direction='right'
            style={{ marginTop: '25px' }}
          >
            {secondRow.map((review) => (
              <ReviewCard key={review.username} {...review} />
            ))}
          </Marquee>
        </div>
      </div>
    )
}


export default Testimonials
