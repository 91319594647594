// PopupModal.js
import React from 'react';
import alert from '../../assets/warning.png'

const PopupModal = ({ isOpen, onClose, onConfirm, message }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed top-0 right-0 left-0 z-50 justify-center items-center w-full h-full flex overflow-x-hidden overflow-y-auto mt-2">
      <div className="relative p-4 w-full max-w-md">
        <div className="relative bg-white rounded-lg shadow">
          <button onClick={onClose} className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center">
            {/* Close Icon */}
          </button>
          <div className="p-4 md:p-5 text-center">
            <img className='w-20 text-center mx-auto' src={alert} alt="" />
            <h3 className="mb-5 text-lg font-normal text-gray-500">{message}</h3>
            <button onClick={onConfirm} className="text-white mb-3 bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">
              Yes, I'm sure
            </button>
            <button onClick={onClose} className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900">
              No, cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupModal;

