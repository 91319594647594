import React, { useContext, useEffect,useRef } from "react";
import { NavLink } from "react-router-dom";
import GlobalContext from "../context/GlobalContext";
import "../styles/Sidebar.css";
import {
  contact_us,
  terms_conditions,
  privacy_policy,
  support_sidebar,
  // settings_sidebar,
  logout,
} from "../staticData/icons";
import Profile from "../assets/profile.png";
import Cookies from "js-cookie";
const userEmail = Cookies.get("email");
const authorizedEmail = ["adeelhaider1541@gmail.com", "kashafshah804@gmail.com"];
const blogItem = {
  id: 1,
  text: "Add Blogs",
  icon: contact_us,
  href: "/blogs",
};
const baseItems = [
  {
    id: 2,
    text: "Terms and Conditions",
    icon: terms_conditions,
    href: "/terms-and-conditions",
  },
  {
    id: 3,
    text: "Privacy Policy",
    icon: privacy_policy,
    href: "/privacy-policy",
  },

  {
    id: 4, text: "Refund Policy",
    icon: support_sidebar,
    href: "/refund-policy",
  },
  {
    id: 4, text: "Payment",
    icon: privacy_policy,
    href: "/payment",
  },
];


const first_box_items =
  userEmail === authorizedEmail ? [...baseItems, blogItem] : baseItems;


function Sidebar({ show, setShow }) {
  const sidebarRef = useRef(null);
  const { userInfo, themeToggle, toggleDarkMode } = useContext(GlobalContext);
  const closeSidebar = () => {
    setShow?.(false); // Use optional chaining to avoid crashing if setShow is not a function
  };

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      closeSidebar();
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside, true);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, true);
    };
  }, []); // Dependencies are correct here

  const handleLogout = () => {
    Cookies.remove("token");
    Cookies.remove("refresh");
    window.location.href = "/login";
  };
  return (
    <div ref={sidebarRef}
      id="sidebar"
      className={show ? "hide-sidebar show-sidebar" : "hide-sidebar"}
    >
      <div className="profile-box py-2">
        <div className="flex align-items-center flex-col gap-1">
          <img
            src={Profile}
            className=" w-[70px] h-[70px] 2xl:w-[80px] 2xl:h-[80px] "
            alt="pic"
          />
          <h5>
            {userInfo?.first_name} {userInfo?.last_name}
          </h5>
          {userInfo?.email && (
            <>
              <p className="text-[12px] 2xl:text-[12px] break-words">
                {userInfo?.email}
              </p>
            </>
          )}
          <div className="my-2">
            <div className="font-medium mb-1 text-white text-[12px] ml-3 ">Dark Mode</div>
            <div onClick={toggleDarkMode} className="flex items-center cursor-pointer">
              <div className={`w-[82px] h-7 flex items-center rounded-full p-1 duration-300 ${themeToggle ? 'bg-green-400' : 'bg-white'}`}>
                <div className={`h-6 w-6 rounded-full bg-[#22828e] shadow-md transform duration-300 ${themeToggle ? 'translate-x-4' : 'translate-x-0'}`}>
                </div>
                <div className={`text-sm font-medium px-2 ${themeToggle ? 'text-white order-first' : 'text-black order-last'}`}>
                  {themeToggle ? 'ON' : 'OFF'}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="first-box">
        <div className="py-3">
          {first_box_items.map((data) => (
            <div key={data.id}>
              <NavLink to={data.href} >
                <div className="icon"> {data.icon} </div>
                <span>{data.text}</span>
              </NavLink>
            </div>
          ))}
        </div>
      </div>
      <div className="second-box">
        <div onClick={handleLogout} className="logout cursor-pointer">
          {logout}
          <span>Logout</span>
        </div>
      </div>
    </div >
  );
}

export default Sidebar;
